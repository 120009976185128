import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Tabs, TabPanel, Tab, TabsList } from '@mui/base';
import { Text, V2Button } from '@web-for-marketing/react-ui';
import { roiResultStyles as classes } from './styleObjects/ROIResultStyles';
import { roiIndustryValueAtom, roiUnitSystemAtom } from '@atoms/roiCalculator';
import { useAtom } from 'jotai';
import { Duration, ROIIndustryOption, Unit } from '@models/ROICalculator';
import {
    getPdfResults,
    getROIPaybackTime,
    getROIResultByDuration,
    roundToLocalString,
    roundTwoDecimal,
} from './ROICalculationHelper';
import { capitalizeFirstLetter } from '@helpers/string';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { generatePdf } from '@services/roiPdfService';
import { FormParamKeys } from '@models/form';
import DemoFormBackground from '@images/SiteWideDemoForm/EN.jpg';

const generateROIPdf = async (industryValue: ROIIndustryOption, unitSystem: Unit): Promise<void> => {
    const pdfresult = getPdfResults(industryValue, unitSystem);
    const response = await generatePdf(pdfresult, 'roi');

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ROI_Calculator_Results.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } else {
        console.error('Failed to generate PDF');
    }
};

export default function ROIResult(): JSX.Element {
    const [industryValue] = useAtom(roiIndustryValueAtom);
    const [unitSystem] = useAtom(roiUnitSystemAtom);
    const [duration, setDuration] = useState(Duration.Month);
    const durations = Object.values(Duration).filter((v) => isNaN(Number(v)));
    const result = getROIResultByDuration(duration, industryValue, unitSystem);

    return (
        <Grid container>
            <Tabs
                value={duration}
                onChange={(_, value) => {
                    setDuration(value as Duration);
                }}
            >
                <TabsList css={classes.tabsList}>
                    {durations.map((value) => (
                        <V2Button
                            key={value}
                            size='small'
                            variant='secondary'
                            color='white'
                            component={Tab}
                            value={value}
                            isSelected={value === duration}
                        >
                            {capitalizeFirstLetter(value)}
                        </V2Button>
                    ))}
                </TabsList>
                <TabPanel value={duration}>
                    <Grid container>
                        <Grid item xs={12} css={classes.singleColumnContainer}>
                            <Text variant='h3' color='inversePrimary' weight='bold' css={classes.textMarginBottom}>
                                Overall increased profits each {duration}
                            </Text>
                            <Text variant='h1' color='inversePrimary'>
                                ${Math.round(result.profitAdded).toLocaleString()}
                            </Text>
                        </Grid>
                        <div css={classes.devider}></div>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary'>
                                ${roundToLocalString(result.revenueAdded)}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                in revenue gained each {duration}
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary'>
                                ${roundToLocalString(result.costSaved)}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                in cost saved each {duration}
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary'>
                                {roundToLocalString(result.distanceSaved)}{' '}
                                {unitSystem === Unit.Imperial ? 'miles' : 'kilometers'}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                saved each {duration}
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary'>
                                {roundToLocalString(result.hoursSaved)} hours
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                saved each {duration}
                            </Text>
                        </Grid>
                        <Grid item xs={12} css={[classes.singleColumnContainer, { marginTop: '2.5rem' }]}>
                            <Text
                                variant='h3'
                                color='inversePrimary'
                                weight={{ sm: 'medium' }}
                                css={classes.textMarginBottom}
                            >
                                Time to see ROI payback
                            </Text>
                            <Text variant='h1' color='inversePrimary'>
                                {getROIPaybackTime(result.timeToPayback)}
                            </Text>
                        </Grid>
                        <div css={classes.devider}></div>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary' css={classes.blurText} aria-hidden>
                                {roundToLocalString(result.fuelSaved)}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                {unitSystem === Unit.Imperial ? 'gallons' : 'liters'} of fuel saved
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary' css={classes.blurText} aria-hidden>
                                ${roundToLocalString(result.fuelCostsSaved)}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                in fuel costs saved
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary' css={classes.blurText} aria-hidden>
                                {roundToLocalString(result.overtimeHoursSaved)}
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                hours of overtime saved
                            </Text>
                        </Grid>
                        <Grid item xs={6} css={classes.twoColumnContainer}>
                            <Text variant='h3' weight='bold' color='inversePrimary' css={classes.blurText} aria-hidden>
                                {roundTwoDecimal(result.emissionSaved)}%
                            </Text>
                            <Text variant='body1' color='inversePrimary'>
                                lower CO2 emissions
                            </Text>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Tabs>
            <Grid item xs={12} container justifyContent='center' css={classes.buttonContainer}>
                <PopoutButton
                    text='Download full report'
                    isV2
                    customSectionKey='MultistepDemoForm'
                    displayMode='bg-popout'
                    backgroundImagePath={DemoFormBackground}
                    css={classes.downloadButton}
                    params={[
                        { key: 'campaign_id', value: '701Pd00000G2iF6IAJ' },
                        {
                            key: FormParamKeys.OnFormSubmitSuccessFunction,
                            value: async () => await generateROIPdf(industryValue, unitSystem),
                        },
                    ]}
                    variant='tertiary'
                />
            </Grid>
        </Grid>
    );
}
