import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const roiResultStyles = {
    tabsList: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1.3rem',
        gap: '2.7rem',
        flexWrap: 'wrap',
        [`@media (max-width: ${breakpoints.md})`]: {
            gap: '1.6rem',
        },
        [`@media (max-width: ${breakpoints.sm})`]: {
            margin: '0',
        },
    },
    devider: {
        borderBottom: `2px solid ${v2Colors.border.inversePrimary}`,
        width: '100%',
    },
    singleColumnContainer: {
        padding: '2.5rem 0',
        height: '100%',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.sm})`]: {
            padding: '2rem 0',
        },
    } as const,
    twoColumnBackgroundContainer: {
        marginTop: '2.5rem',
        '&:nth-child(even)': {
            paddingLeft: '.85rem',
        },
        '&:nth-child(odd)': {
            paddingRight: '.85rem',
        },
        [`@media (max-width: ${breakpoints.sm})`]: {
            '&:nth-child(even)': {
                paddingLeft: 0,
            },
            '&:nth-child(odd)': {
                paddingRight: 0,
            },
        },
    },
    twoColumnContainer: {
        padding: '2.5rem 0 0',
        [`@media (max-width: ${breakpoints.sm})`]: {
            padding: '2rem 0 0',
        },
    },
    buttonContainer: {
        marginTop: '2.5rem',
    },
    textMarginBottom: {
        marginBottom: '1rem',
    },
    downloadButton: {
        [`@media (max-width: ${breakpoints.sm})`]: {
            width: '100%',
        },
    },
    blurText: {
        filter: 'blur(1rem)',
        userSelect: 'none',
    },
} as const;
